// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-hello-world-index-mdx": () => import("./../../../src/pages/blogs/hello-world/index.mdx" /* webpackChunkName: "component---src-pages-blogs-hello-world-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-mdx-tsx": () => import("./../../../src/templates/blog-mdx.tsx" /* webpackChunkName: "component---src-templates-blog-mdx-tsx" */)
}

